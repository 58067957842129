import React from 'react';

const Contact = () => {
  return (
    <div className="container contact-section">
      <div className='row'>
        <div className='col-sm mb-3'>
          <label for="exampleFormControlInput1" class="form-label">Enter your email address:</label>
          <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com"></input>
        </div>
      </div>
      <div className='row'>
        <div className='col-sm'>
          <label for="exampleFormControlTextarea1" class="form-label">Enter your message:</label>
          <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
        </div>
      </div>
    </div>
  );
};

export default Contact;