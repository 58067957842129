import React from 'react';

const Portfolio = () => {
  return (
    <div className="container portfolio-section">
      <div className='row'>
        <div className='col-sm'>
          <h2>Portfolio</h2>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;