import React, { useState } from 'react';
import { Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, NavLink } from 'reactstrap';

const AppNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Navbar color="dark" dark expand="md">
      <NavbarBrand className="navbar-brand" href="/">NP</NavbarBrand>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="ml-auto" navbar>
          <NavItem className='nav-item'>
            <NavLink href="/about">About</NavLink>
          </NavItem>
          <NavItem className='nav-item'>
            <NavLink href="/blog">Blog</NavLink>
          </NavItem>
          <NavItem className='nav-item'> 
            <NavLink href="/portfolio">Portfolio</NavLink>
          </NavItem>
          <NavItem className='nav-item'>
            <NavLink href="/contact">Contact</NavLink>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default AppNavbar;
