import React from 'react';

const Blog = () => {
  return (
    <div className="container blog-section">
      <div className='row'>
        <div className='col-sm'>
          <h2>Coming Soon...</h2>
        </div>
      </div>
    </div>
  );
};

export default Blog;